<template>
  <v-dialog v-model="dialog" persistent width="750">
    <v-card>
      <v-card-title class="text-h5" v-if="action === 'Create'"
        >Create User</v-card-title
      >
      <v-card-title class="text-h5" v-else>Edit User</v-card-title>

      <v-card-text>
        <v-form ref="userForm">
          <v-row>
            <v-col md="6" cols="12">
              <label>Client name</label>
              <v-text-field
                label="Enter name"
                outlined
                dense
                :rules="rules"
                single-line
                disabled
                hide-details="auto"
                v-model="userForm.client_name"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" v-if="action === 'Create'">
              <label>Email ID</label>
              <v-text-field
                label="Enter email id"
                outlined
                dense
                disabled
                :rules="emailRules"
                single-line
                hide-details="auto"
                v-model="userForm.email"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" v-if="action === 'Create'">
              <label>Client ID</label>
              <v-text-field
                label="Enter client id"
                outlined
                dense
                disabled
                :rules="rules"
                single-line
                hide-details="auto"
                v-model="userForm.client_id"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <label>Contact number</label>
              <v-text-field
                label="Enter contact number"
                outlined
                dense
                single-line
                disabled
                hide-details="auto"
                v-model="userForm.phone_number"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <label>Home address</label>
              <v-text-field
                label="Enter home address"
                outlined
                dense
                disabled
                single-line
                hide-details="auto"
                v-model="userForm.address"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <label>Zip code</label>
              <v-text-field
                label="Enter zip code"
                outlined
                dense
                disabled
                single-line
                hide-details="auto"
                v-model="userForm.zip_code"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="var(--primary)"
          dark
          :loading="loading"
          @click="createUser"
          v-if="action === 'Create'"
        >
          Create</v-btn
        >
        <v-btn
          color="var(--primary)"
          dark
          :loading="loading"
          @click="editUser"
          v-else
        >
          Edit</v-btn
        >
        <v-btn color="grey darken-1" dark @click="close"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['dialog', 'action', 'user'],
  data: () => ({
    userForm: {
      client_name: '',
      email: '',
      client_id: '',
      zip_code: '',
      phone_number: '',
      address: '',
      user_id: '',
    },
    rules: [(v) => !!v || 'Field is required '],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+/.test(v) || 'E-mail is invalid',
    ],
    loading: false,
  }),
  computed: {
    ...mapGetters(['clientInfo']),
  },
  methods: {
    close() {
      this.$refs.userForm.reset()
      // reset field that is hidden as well
      this.userForm.client_id = ''
      this.$emit('refresh')
      this.$emit('update:dialog', false)
    },
    async createUser() {
      if (this.$refs.userForm.validate()) {
        this.loading = true
        try {
          await this.$store.dispatch('create_user', this.userForm)
          this.$refs.userForm.reset()
          this.$root.snackbar.show({
            message: 'User successfully created',
            color: 'success',
          })
          this.close()
        } catch (error) {
          this.$root.snackbar.show({
            message: error.response.data.message,
            color: 'error',
          })
        } finally {
          this.loading = false
        }
      } else {
        this.$root.snackbar.show({
          message: 'Required fields are missing.',
          color: 'error',
        })
      }
    },
    async editUser() {
      let payload = {}
      payload.user_id = this.userForm.user_id
      payload.client_name = this.userForm.client_name
      payload.address = this.userForm.address
      payload.phone_number = this.userForm.phone_number
      payload.zip_code = this.userForm.zip_code
      try {
        this.loading = true
        await this.$store.dispatch('edit_user', payload)
        this.$root.snackbar.show({
          message: 'User updated successfully',
          color: 'success',
        })
        this.close()
      } catch (error) {
        this.$root.snackbar.show({
          message: error.response.data.message,
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    dialog: function () {
      if (this.dialog && this.action !== 'Create') {
        this.userForm.client_name = this.user.client_name
        this.userForm.user_id = this.user.user_id
        this.userForm.phone_number = this.user.phone_number
        this.userForm.address = this.user.address
        this.userForm.zip_code = this.user.zip_code
      } else if (this.dialog && this.action === 'Create') {
        this.userForm.client_id = this.clientInfo.clientId
        this.userForm.client_name = this.clientInfo.clientName
        this.userForm.address =
          (this.clientInfo.clientAddress1 !== null) &
          (this.clientInfo.clientAddress2 !== null)
            ? this.clientInfo.clientAddress1 +
              ' ' +
              this.clientInfo.clientAddress2
            : ''
        this.userForm.email = this.clientInfo.clientEmail
        this.userForm.phone_number = this.clientInfo.clientPhoneNumber
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text {
  padding-top: 15px;
  padding-bottom: 15px;
}
label {
  margin-bottom: 5px;
  display: inline-block;
}
</style>